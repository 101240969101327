export default {
  title: "Contact Information",
  data: [
    {
      name: "Address",
      content: "No.xxxxx, xxxxxxxxxxxxx Street, xxxxxxxxxxxx Township, Yangon +95 xxxxxxxxxx, +95 xxxxxxxxx, +95 xxxxxxxxx xxxxx@sbbaby.com"
    },
    {
      name: "Phone",
      content: "+95 xxxxxxxxxx, +95 xxxxxxxxx, +95 xxxxxxxxx"
    },
    {
      name: "Email",
      content: "xxxxx@sbbaby.com"
    }
  ]
}