const axios = require('axios')
const config = require('./_config')

export const get_proddetail = (param) => {
return axios.get(`${config.default.url}/detail/product/list`, {
  params: {
    ...param
  }
})
.then((res) => res.data.data[0])
.catch(() => "server error")
}