import React from 'react'
import PropTypes from 'prop-types'
import Promo from '../promotion'
import Slider from "react-slick";
import SecTitle from '../sectiontitle';

export default function PromoBanner({promotions, title}) {
  const settings = {
    dots: false,
    infinite: promotions !== null && promotions.length < 3 ? false : true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  
  return (
    <>
      {title && promotions.length > 0 && <SecTitle text={title}/>}
      <section className="slick-promo">
        {
          promotions !== null &&
          <Slider {...settings}>
          {
            promotions.length > 0 &&
            promotions.map((promo, key) => (
              <figure key={key}>
                <Promo img={promo.promo_image} url={promo.id} />
              </figure>
            ))
          }
          </Slider>
        }
      </section>
    </>
  )
}
// PromoBanner.propTypes = {
//   promotions: PropTypes.array.isRequired
// }
