const axios = require('axios')
const config = require('./_config')
export const get_prodbrand = (param) => {
return axios.get(`${config.default.url}/product/brand`, {
    params: {
        ...param
    }
})
.then((res) => res.data.data)
.catch(() => "server error")
}