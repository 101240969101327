import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reset, order, order_name } from '../../redux-actions/action'
import Button from '../../components/button'
import Table from '../../components/table'
import _ from 'underscore'
import currency from 'currency.js'
import { post_payment, post_cart, post_shipinfo, post_guest_cart, post_guestshipinfo, post_guestpayment, getui } from '../../controller'
import config from '../../config'
export default function Confirm({ state, play, payment }) {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()
  const shopping_cart = useSelector(state => state.cart)
  const login = useSelector(state => state.login)
  const [collection, setcollection] = useState(null)
  const [selectedPaymentId, setselectedPaymentId] = useState(null)
  const emptyCart = useDispatch()
  const shipinfo = JSON.parse(window.localStorage.getItem('shipinfo'))
  // console.log(shipinfo)
  useEffect(() => {
    let paymentlist = _.map(payment, (item) => {
      return {
        text: item.payment_method_name,
        value: item.payment_method_id,
        desc: item.payment_method_desc,
        code: item.payment_method_code
      }
    })
    setselectedPaymentId(payment[0].payment_method_id)
    setcollection(paymentlist)
  }, [])
  
  return (
    <React.Fragment>
      <div className="col-lg-6">
        {
          collection !== null &&
          collection.map((info,key) => 
            info.code === "COD" && <div className='payment-list' key={key}>
              <input type='radio' defaultValue={info.value} id={key} name='payment_method' defaultChecked={key === 0 ? true : false}/>
              <label htmlFor={key}>{info.text}</label>
              <p className='payment-desc'>{info.desc}</p>
            </div>
          )
        }
        <div className="row">
          <div className="col-lg-12">
            <div className="confirm-btn">
              <Button
                text={getui(uitext, 'back', lang)}
                lfticn={require('../../assets/img/leftarrow-white.png')}
                play={() => {
                  play(state -= 1)
                  window.scrollTo(0, 0)
                }}
              />
            
              <Button
                text={getui(uitext, 'order_confirm', lang)}
                rhticn={require('../../assets/img/rightarrow-white.png')}
                play={() => {
                  let data = {
                    user_id: login && login.userid,
                    sale_order_line: _.map(
                      shopping_cart, data => ({
                        variant_id: data.varient_id,
                        product_id: Number(data.product_id),
                        product_uom_qty: data.product_uom_qty
                      })
                    )
                  }
                  if (login !== null) {
                    post_cart(data, login.access_token)
                      .then(res => {
                        if (res.status === 'Success!') {
                          // console.log(res.sale_order_name)
                          dispatch(order(res.Order_id))
                          dispatch(order_name(res.sale_order_name))
                          let shipinfo = JSON.parse(window.localStorage.getItem('shipinfo'))
                          post_shipinfo({
                            ...shipinfo,
                            order_id: res.Order_id
                          }, login.access_token)
                          .then(() => {
                            // console.log(res, 'shipinfo')
                            if (res.status === "Success!") {
                              post_payment(res.Order_id, selectedPaymentId, login.access_token)
                              .then(response => {
                                console.log(response)
                                if(response.data.status === 'Success!') {
                                  // play(state += 1)
                                  emptyCart(reset())
                                  window.location.href = '/success/ordersuccess'
                                }
                              })
                              .catch(err => window.location.href = '/login')
                            }
                          })
                          
                        }
                      })
                  } else {
                    post_guest_cart(data)
                    .then(resdata => {
                      if (resdata.status === 'Success!') {
                        // console.log(resdata)
                        dispatch(order(resdata.Order_id))
                        dispatch(order_name(resdata.sale_order_name))
                        let shipinfo = JSON.parse(window.localStorage.getItem('shipinfo'))
                        post_guestshipinfo({
                          ...shipinfo,
                          order_id: resdata.Order_id
                        })
                        .then(res => {
                          if (res.status === 'Success!') {
                            post_guestpayment(resdata.Order_id, selectedPaymentId)
                            .then(response => {
                              // console.log(response.data)
                              if(response.data.status === 'Success!') {
                                // play(state += 1)
                                emptyCart(reset())
                                window.location.href = '/success/ordersuccess'
                              }
                            })
                            .catch(err => window.location.href = '/login')
                          }
                        })
                      }
                    })
                  }
                }}
              />
            </div>
            
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="side-widget address text-right">
          <Table
            heads={["PRODUCTS", "QTY", "PRICE"]}
            body={
              shopping_cart.map((info, key) => (
                (
                  <tr key={key}>
                    <td className="qty_amt">
                      <div className="product">
                        <img src={info.img || require('../../assets/img/no-img.jpg')} alt={info.name} className="prduct-img" />
                        <div className="desc">
                          <p>{info.name}</p>
                        </div>
                      </div>
                    </td>
                    <td className="price">
                      {info.product_uom_qty}
                    </td>
                    <td>{currency(info.discount_price * info.product_uom_qty, config.currency).format()}</td>
                  </tr>
                )
              ))
            }
          />
          <ul className="pricelist">
            <li>
              <span className="totalspan">Subtotal : </span> <span className="currency">{
                currency(_.reduce(
                  _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                  (memo, price) => memo + price
                ), config.currency).format()
              }</span>
            </li>
            <li >
              <span className="totalspan">
              {
                Number(shipinfo.delivery) > 0 ? "Delivery Charges : " : "Delivery Charge (Free) : "
              }
              </span> <span className="currency">{
                currency(shipinfo.delivery, config.currency).format()
              }</span>
            </li>
            <li className="mb-5">
              <span className="totalspan">Total : </span> <span className="currency">{
                currency((_.reduce(
                  _.map(shopping_cart, data => data.product_uom_qty * data.discount_price),
                  (memo, price) => memo + price
                ) + shipinfo.delivery), config.currency).format()
              }</span>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}
