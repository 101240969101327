import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

export default function ProductSlider({imglink}) {
  const [nav1, setnav1] = useState(null); 
  const [nav2, setnav2] = useState(null);

  let _topSlider = []
  let _bottomSlider = []

  useEffect(() => {
    setnav1(_topSlider);
    setnav2(_bottomSlider);
  }, [_topSlider, _bottomSlider]);
  
  return (
    <section className="product-slide">
      <ul className="navigator">
        <Slider
          asNavFor={nav1}
          ref={slider => (_bottomSlider = slider)}
          slidesToShow={imglink !== null && imglink.length > 4 ? 4 : imglink.length}
          swipeToSlide={true}
          focusOnSelect={true}
          vertical= {true}
        >
          {
            imglink !== null && imglink.length > 0 &&
            imglink.map((info,key) => 
            <li key={key}>
              <img src={info} alt="sbbaby" />
            </li>
            )
          }
        </Slider>
      </ul>
      <ul className="product-img">
        <Slider
          asNavFor={nav2}
          ref={slider => (_topSlider = slider)}
        >
          {
            imglink !== null && imglink.length > 0 &&
            imglink.map((info, key) => 
            <li key={key}>
              <InnerImageZoom src={info} zoomSrc={info} alt="sbbaby" />
            </li>
            )
          }
        </Slider>
      </ul>
    </section>
  )
}
