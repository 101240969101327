import React, { useState, useEffect } from 'react'
// data
import SecNav from './_secnav'
import PriNav from './_prinav'
import {get_footer} from '../../controller'
import { useSelector } from 'react-redux'

export default function Header() {
    const [ footer, setfooter ] = useState(null)
    const lang = useSelector(state => state.lang)
    useEffect(() => {
        get_footer({
          lang: lang
        })
        .then((res) => {
          setfooter(res)
        })
      }, [setfooter])
    
    return (
        <header className="header-main">
            {
                footer !== null &&
                <SecNav socialData={footer} />
            }
            <PriNav />
        </header>
    )
}