const axios = require('axios')
const config = require('./_config')

export const get_wish_list = (param, access_token) => {
    return axios.get(`${config.default.url}/wishlist`,{
        headers: {
          'access-token':  access_token
        },
        params: {
            ...param
        }
    }
        
    )
    .then((res) => res.data.data)
    .catch(() => "server error")
}

