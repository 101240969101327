import React from 'react'
import PropTypes from 'prop-types'
export default function Input({type = "text", options, label, placeholder, id, play, value, required, min}) {
  return (
    <div className={"form-data " + (type === "checkbox" && "check-filed")}>
      {
        label && <label htmlFor={id}>{label}</label>
      }
      {
       (type === 'text' || type === 'number' || type === 'checkbox' || type === 'password' || type === 'email' || type === 'tel' ) && <input min={min} id={id} type={type} placeholder={placeholder} onChange={play} defaultValue={value} required={required}/>
      }
      {
       (type === 'textarea' ) && <textarea id={id} type={type} placeholder={placeholder} onChange={play} defaultValue={value} />
      }
      {
        type === 'select' && 
        <select id={id} onChange={play} value={value} required>
          {
            options.map((option, key) => {
            return <option value={option.value} key={key} >{option.text}</option>
          })}
        </select>
      }
    </div>
  )
}
Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array
}