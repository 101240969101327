import { get_proddetail, get_productqty } from '../controller'
import _ from 'underscore'
export const addToCart = (product_id) => {
  return get_proddetail({product_id})
  .then(res => {
    let attr = _.map(_.map(res.attribute_list, data => data.attributes), data =>  _.sortBy(data, 'price')[0])
    let varient_id = _.map(attr, data => data.id).toString()
    
    let uniqueid = product_id + varient_id
    const cart = JSON.parse(localStorage.getItem('cart'))
    let current_qty = _.find(cart, item => item.uniqueid === uniqueid) && _.find(cart, item => item.uniqueid === uniqueid).product_uom_qty

    return get_productqty({
      product_id,
      varient_id,
      qty: (current_qty || 0) + 1
    }).then(({instock}) => instock ? {...res, selected_varient: attr} : 'out of stock')
  })
}