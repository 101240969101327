import _ from 'underscore'
const ordername = (state = localStorage.getItem('ordername'), action) => {
  switch (action.type) {
      case 'ORDERNAME':
            localStorage.setItem('ordername', action.data)
            return action.data
      default :
          return state
  }
}

export default ordername