import React from 'react'

export default function Success({success}) {
  return (
    <React.Fragment>
      <section className="col-12">
        <h2>RESERVATION PROCESS IS SUCCESS</h2>
      </section>
    </React.Fragment>
  )
}
