import React, {useEffect} from 'react';

const ImgFake = (props) => {
  const node = "ImgFake-" + Math.floor(Math.random() * 9999);
  useEffect(() => {
    let parent = document.querySelector(`.${node}`);
    if(props.unresponsive) {
      parent.style.width = props.width + 'px';
      parent.style.height = props.height + 'px';
      parent.style.opacity = 1;
    } else {
      parent.style.height = ((parent.offsetWidth / props.width) * props.height) + 'px';
    }
    // window.addEventListener('resize', function() {window.location.reload()})
  }, [node, props])

  return <div className={node} style={{
    opacity: 0,
  }}>&nbsp;</div>
}

export default ImgFake;
