const axios = require('axios')
const config = require('./_config')

// export const post_remove_wish_list = (param, access_token) => {
//     return axios.get(`${config.default.url}/wishlist/remove`,{
//         // headers: {
//         //   'Authorization':  access_token
//         // },
//         params: {
//             ...param
//         }
//     }).then((res) => res.data.data)
//         .catch(() => "server error")
// }

export const post_remove_wish_list = (product_id, variant_id, user_id, access_token) => {
    return axios.create({
      headers: {
          'access-token': access_token
      }
    }).post(`${config.default.url}/wishlist/remove?user_id=${user_id}&product_id=${product_id}&variant_id=${variant_id}`)
      .then(res => res.data.data)
      .catch(err => err)
    }