import React, { useState, useEffect } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { useDispatch } from 'react-redux'
import { login_action } from '../../redux-actions/action'
import { get_state, post_profile, getui, get_profile } from '../../controller'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'underscore'
import {township} from '../../utils/township_format'
import './style.scss'

export default function Edituserprofile() {
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [region, setregion] = useState(null)
  const login = useSelector(state => state.login)
  login === null && (window.location.href='/login')
  const [profile, setprofile] = useState({
    name: login[0].name,
    email: login[0].email,
    mobile: login[0].mobile,
    address1: login[0].street,
    city: login[0].city,
    zipcode: login[0].zip,
    state_division: Number(login[0].state_id),
  })
  
  let uid = login.userid
  let token = login.access_token
  let expires = login.expire
  
  const [city, setCity] = useState(null)
  const [cityName, setCityName] = useState(null)
  useEffect(() => {
    //get state list
    get_state()
    .then(res => {
      let city = township.township_format(res)
      setCity(city)
      // console.log(city)
      // console.log(city[_.keys(city)[0]][0].state_id)
      setprofile({
        ...profile
      })
    })
    window.scrollTo(0, 0)
  }, [])

  const handleChange = (e, id) => {
    let data = {
      ...profile,
      [e.target.id]: e.target.id === 'state_division' ? Number(e.target.value) : e.target.value
    }
    id && (data = {...data, state_division: Number(id)})
    
    if(e.target.id === 'city') {
      data.state_division = ""
    }
    // setprofile(data)
  }

  let dispatch = useDispatch()
  const profileChangeSuccess = () => {
    get_profile(uid, token)
    .then((res) => {
      dispatch(login_action({
        ...res,
        userid: uid,
        access_token: token,
        expire: expires
      }))
      window.location.href='/profile'
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(profile)
    let data = {
      user_id : uid,
      ...profile
    }
    // console.log(data, '....')
    post_profile(data, token)
    .then((res) => {
        res.status === 'Success!' ?
        profileChangeSuccess() :
        alert('Profile change fail!')
    })
    .catch(err => window.location.href='/login')
  }
  console.log(profile)
  return (
    <article id="edituserprofile">
      <section className="userprofile">
        <div className="container profileform formsection">
          <h2>{getui(uitext, 'edit_profile', lang)}</h2>
          <form onSubmit={handleSubmit}>
          <div className="row">
          
            <div className="col-lg-6">    
                  <div className="form-name">
                      <p>Name</p>                                
                      <Input required id="name" value={login[0].name} type="text" placeholder="Name" play={(e) => {setprofile({...profile, name: e.target.value})}} />
                  </div>
                  <div className="form-name">
                      <p>Email</p>                                
                      <Input id="email" value={login[0].email} type="email" placeholder="Email" play={(e) => setprofile({...profile, email: e.target.value})} />
                  </div>
                  <div className="form-name">
                      <p>Phone</p> 
                      <Input required id="mobile" value={login[0].mobile} type="number" placeholder="phone" play={(e) => setprofile({...profile, mobile: e.target.value})} />
                  </div>
                  <div className="form-name">
                      <p>Address</p>
                      <Input id="address1" value={login[0].street} type="text" placeholder="address 1" play={(e) => setprofile({...profile, address1: e.target.value})} />
                  </div>
                </div>
                <div className="col-lg-6">
                  {
                    city !== null &&
                    <>
                      <div className="form-password city-margin">
                        <Input type="select" 
                          id="city"
                          label="City"
                          options={[{value: '', text: 'select a city'}, ..._.map(city, (val, key) => ({value: key, text: key}))]} 
                          value={profile.city}
                          play={
                            (e) =>{
                              setCityName(e.target.value)
                              setprofile({...profile, city: e.target.value, state_division: ''})
                              // handleChange(e, city[e.target.value][0].state_id)
                            }
                          }
                          />
                      </div>
                      <div className="form-password zip-township">
                        <Input type="select" 
                          id="state_division"
                          label="Township"
                          options={
                            [{value: '', text: 'select a township'} , ..._.map(city[profile.city], item => ({value: item.state_id, text: item.name}))]
                          } 
                          value={profile.state_division}
                          play={(e) => {
                            setprofile({...profile, state_division: e.target.value})
                          }}
                          />
                      </div>
                    </>
                  }
                  <div className="form-name">
                      <p>ZIP Code</p>
                      <Input id="zipcode" value={login[0].zip} type="number" placeholder="ZIP Code" play={(e) => setprofile({...profile, zipcode: e.target.value})} />
                  </div>
                  
                
            </div>
            <div className="col-md-12">
                    <div className="btn-gp">
                      <Button  
                          text="Update"
                          addclass="btn-checkout btn-login"
                      /> 
                      

                    </div>
                  </div>
            
            

          </div>
          </form>

        </div>
      </section>
    </article>
  )
}
