import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Pagination from 'rc-pagination';
import {isMobile} from 'react-device-detect';
import Breadcrumb from '../../components/breadcrumb'
import Card from '../../components/card'
import './style.scss'
import { get_prodlist } from '../../controller'

export default function Search() {
    const [currentPage, setCurrentPage] = useState(1)
    const lang = useSelector(state => state.lang)
    const [ products, setproducts ] = useState(null)
    const [ count, setcount ] = useState(null)
    const searchname = useParams().search
    let post = products !== null && products.length
    let postSize = 9
    if (isMobile) {
        postSize = 8
    }
    useEffect(() => {
        get_prodlist({
            product_name: searchname,
            lang: lang,
            product_per_page: 10000000
        })
        .then(res => {
            setproducts(res.product_list)
            setcount(res.product_list.length)
        })
        window.scrollTo(0, 0)
    }, [setproducts])
    return (
        <article id="productsearchresult">
            <Breadcrumb pagename="Search Result"/>
            {
                products !== null &&
                <div className="container searchresult">
                <div className="titlewrap">
                    <h3 className="result-title">SEARCH RESULTS FOR "{searchname}" — ({ products.length === 0 ? 0 : count}) RESULT.</h3>
                </div>
                <div className='row card-row'>
                    {
                        products.length === 0 ?
                        <div className='col-lg-12'>
                            <div className='noresult-wrap'>
                                <h4 className='no-result'>No Result Found</h4>
                            </div>
                        </div>
                        :
                        products.slice((postSize * currentPage) - postSize, postSize * currentPage).map((info,key) => {
                        return (
                            <Card info={info} key={key} />
                        )
                        })
                    }
                </div>
                {
                    products !== null && products.length > 9 &&
                    <div className='pagi-wrap'>
                        <Pagination
                        onChange={(current) => {
                            setCurrentPage(current)
                            window.scrollTo(0, 0)
                        } }
                        current={currentPage}
                        total={post}
                        defaultPageSize={postSize}
                        showLessItems={true}
                        />
                    </div>
                }
            
            </div>
        
            }
            </article>
    )
    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         products: null,
    //         count: null
    //     }
    // }
    // render() {
    //     const {products} = this.state;
    //     const searchname = this.props.match.params.search;
    //     this.state.products === null &&
    //     get_prodlist({
    //         product_name: searchname
    //     })
    //     .then((res) => {
    //         this.setState({
    //             products: res.items,
    //             count: res.items.length
    //         })
    //     })
        
    // }
}
