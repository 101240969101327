import _ from 'underscore'
const catekey = (state = localStorage.getItem('catekey'), action) => {
  switch (action.type) {
      case 'CATEKEY':
            localStorage.setItem('catekey', action.data)
            return action.data
      default :
          return state
  }
}

export default catekey