import _ from 'underscore'
const orderno = (state = localStorage.getItem('orderno'), action) => {
  switch (action.type) {
      case 'ORDER':
            localStorage.setItem('orderno', action.data)
            return action.data
      default :
          return state
  }
}

export default orderno