import About from "../layout/about";
import Home from "../layout/home/Home";
import Login from "../layout/login";
import Policy from "../layout/policy";
import ProductDetail from "../layout/product-detail";
import ShoppingCart from "../layout/shoppingcart";
import Signup from "../layout/signup";
import Terms from "../layout/terms";
import Contactus from "../layout/contactus";
import Profile from "../layout/profile";
import Brand from "../layout/brandlisting";
import Edituserprofile from "../layout/edituserprofile";
import Productsearchresult from "../layout/productsearchresult";
import Gallery from "../layout/gallery";
import Wishlist from "../layout/wishlist";
import Orderdetail from "../layout/orderdetail";
import Promolist from "../layout/promolist";
import Promodiscount from "../layout/promodiscount";
import Success from "../layout/successful";
import Otp from "../layout/otp";
import Resetpassword from "../layout/resetpassword";
import Changepass from "../layout/changepass";
import Usercheck from "../layout/usercheck";
import NotFound from "../layout/404";
import TermsMobile from "../layout/terms_mobile";
import PolicyMobile from "../layout/policy_mobile";

export default [
  {
    path: '/',
    component: Home
  },
  {
    path: '/cart',
    component: ShoppingCart
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/product/:productid/:productname',
    component: ProductDetail
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/policy',
    component: Policy
  },
  {
    path: '/termandcondition',
    component: Terms
  },
  {
    path: '/contactus',
    component: Contactus
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/brandlisting/:special/:brandid/:brandname',
    component: Brand
  },
  {
    path: '/edituserprofile',
    component: Edituserprofile
  },
  {
    path: '/productsearchresult/:search',
    component: Productsearchresult
  },
  {
    path: '/gallery/:galleryid',
    component: Gallery
  },
  {
    path: '/wishlist',
    component: Wishlist
  },
  {
    path: '/orderdetail/:orderid',
    component: Orderdetail
  },
  {
    path: '/promolist/:promoid',
    component: Promolist
  },
  {
    path: '/promodiscount',
    component: Promodiscount
  },
  {
    path: '/success/:name',
    component: Success
  },
  {
    path: '/otp/:name',
    component: Otp
  },
  {
    path: '/resetpassword',
    component: Resetpassword
  },
  {
    path: '/changepass',
    component: Changepass
  },
  {
    path: '/usercheck',
    component: Usercheck
  },
  {
    path: '/mobile/policy/:lang',
    component: PolicyMobile
  },
  {
    path: '/mobile/term/:lang',
    component: TermsMobile
  },
  {
    path: '*',
    component: NotFound
  }
]