import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getui } from '../../controller'

export default function Breadcrumb({pagename, links}) {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  return (
    <section className="breadcrumb">
      <div className="container">
        <h2 className="title">{pagename}</h2>
        <ul>
          <li>
            <Link to="/">{getui(uitext, 'home', lang)}</Link>
          </li>
          {
            links && links.map((item, key) => 
            <li key={key}>
              {item.link ? <Link to={item.link}>{item.name}</Link> : <span>{item.name}</span>}
            </li>
            )
          }
        </ul>
      </div>
    </section>
  )
}
Breadcrumb.propTypes = {
  pagename: PropTypes.string.isRequired
}
