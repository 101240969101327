import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import _ from 'underscore'
import Breadcrumb from '../../components/breadcrumb'
import Card from '../../components/card'
import SecTitle from '../../components/sectiontitle'
import ProductInfo from './_productinfo'
import './style.scss'
import { useParams, useHistory } from 'react-router-dom'
import { getui } from '../../controller'

export default function ProductDetail() {
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [ relatedProducts, setCatId] = useState(null)
  const [ cagName, setCagName] = useState(null)
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [relatedProducts])
  // console.log(cagName, 'cagname')
  return (
    <article id='product-detail'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <a onClick={() => {
              history.goBack()
            }} 
            className='backbtn'>
              <img src={require('../../assets/img/arrow-white.svg')} alt={"search sb baby"}/>
            </a>
          </div>
        </div>
      </div>
      <Breadcrumb pagename={getui(uitext, 'product_detail', lang)} links={[
        {
          name: cagName
        },
        {
          name: decodeURIComponent(useParams().productname)
        }
      ]}/>
      <section className='product-info-wrap'>
        <div className='container'>
          <h2 className='detail-title'>{getui(uitext, 'product_detail', lang)}</h2>
          <ProductInfo setCatId={setCatId} setCagName={setCagName} />
        </div>
      </section>
      <section className='recently'>
        <div className='container'>
          {relatedProducts !== null && relatedProducts.length > 0 && <SecTitle text="Related Products"/>}
          <div className='row card-row'>
            { 
              relatedProducts !== null && relatedProducts.length > 0 &&
              relatedProducts.map((info,key) => <Card info={info} key={key} custom_class="col-lg-3 col-md-4 col-6 card-col"/>)
            }
          </div>
        </div>
      </section>
    </article>
  )
}
