const config = require('./_config')
const axios = require('axios')

export const get_order = (uid, access_token) => {
  return axios.create({
    headers: {
      'access-token': access_token
    }
  })
  .get(`${config.default.url}/saleorder?user_id=${uid}`)
  .then(res => res.data.data)
  .catch(() => "server error")
}