import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import ImgFake from '../fakeimg'
import './style.scss'

export default function Promo({url, img}) {
  return (
    <Link className='promotype' to={'/promolist/'+url+''} style={{background: `url('${img || require('../../assets/img/no-img.jpg')}') no-repeat center / cover`}}>
      <ImgFake width={24} height={24}/>
    </Link>
  )
}

// Promo.propTypes = {
//   img: PropTypes.string.isRequired
// }