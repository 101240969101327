import { combineReducers } from 'redux'

import demo from './demo'
import cart from './cart'
import lang from './lang'
import login from './login'
import orderno from './orderno'
import ordername from './ordername'
import mobile from './getmobile'
import otp from './otp'
import translate from './translation'
import getotp from './otp'
import {popup} from './popup'
import catekey from './catekey'

const STORE = combineReducers({
    demo,
    cart,
    lang,
    login,
    orderno,
    ordername,
    mobile,
    otp,
    translate,
    getotp,
    popup,
    catekey
})

export default STORE