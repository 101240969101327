import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'underscore'
import Breadcrumb from '../../components/breadcrumb'
import Card from '../../components/card'
import SecTitle from '../../components/sectiontitle'
import Sidebar from '../../components/sidebar'
import BrandList from './_brandlist'
import Step from './_step'
import Pagination from 'rc-pagination';
import { isMobile } from 'react-device-detect';
import { get_prodlist, get_prodbrand, get_prodtype, getui } from '../../controller'
import './style.scss'

export default function Brand() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [products, setproducts] = useState(null)
  const special = useParams().special
  const id = useParams().brandid
  const brandid = useParams().brandname
  const brandName = useParams().brandname
  const [brand, setBrand] = useState([])
  const [step, setStep] = useState([])
  const [itemQuery, setItemQuery] = useState({
    brand: null,
    step: null
  })
  let post = products !== null && products.length
  let postSize = 9
  if (isMobile) {
    postSize = 8
  }
  useEffect(() => {
    if (special !== 'brands') {
      get_prodlist({
        categ_id: id,
        lang,
        product_per_page: 10000000
      })
        .then(res => {
          if (special === 'special' && res.product_list.length > 0) {
            // brand
            // let brand = _.map(res.product_list, item => item.brand_id)
            let brand = _.filter(_.map(res.product_list, item => item.brand_id), brand => brand !== null)

            brand.toString() !== "" &&
              get_prodbrand({
                brand_ids: brand.toString()
              }).then(res => setBrand(res))
                .catch(err => {
                  setBrand([])
                })
            // step
            let selectedType = _.map(res.product_list, item => item.product_type_id)
            let type = []
            get_prodtype()
              .then(res => {
                res.map(item => selectedType.includes(item.id) && type.push(item))
                setStep(type)
              })
          }

          let brand_filter = _.filter(res.product_list, item => {
            if ((itemQuery.brand === item.brand_id || itemQuery.brand === null) &&
              (itemQuery.step === item.product_type_id || itemQuery.step === null)
            ) {
              return item
            }
          })
          setproducts(brand_filter)
        })
        .catch(() => setproducts([]))
    } else {
      get_prodlist({
        brand_id: brandid,
        lang,
        product_per_page: 10000000
      })
        .then(res => {
          let brand_filter = _.filter(res.product_list, item => {
            if ((itemQuery.brand === item.brand_id || itemQuery.brand === null) &&
              (itemQuery.step === item.product_type_id || itemQuery.step === null)
            ) {
              return item
            }
          })
          setproducts(brand_filter)
        })
        .catch(() => setproducts([]))
    }


  }, [id, itemQuery, lang, special])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  window.gtag('config', 'G-GTX02WJT84', { 'page_title': brandName, page_path: window.location.pathname + window.location.search })

  return (
    <article id='brand'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            {/* <Link to={special !== "brands" ? '/#brandlisting' : '/'} className='backbtn'> */}
            <p className="backbtn" onClick={() => history.goBack()}>
              <img src={require('../../assets/img/arrow-white.svg')} alt={"search sb baby"} />
            </p>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <Breadcrumb pagename='' links={[{ name: brandName === 'false' ? '' : special !== "brands" ? decodeURIComponent(brandName) : decodeURIComponent(id) }]} />
      <div className='container'>
        <div className='row'>

          <div className='col-lg-9'>
            {
              special === 'special' && products !== null &&
              <>
                {
                  products &&
                  <>
                    {
                      brand.length > 0 &&
                      <div className="brand-wrap">
                        <SecTitle text={getui(uitext, 'brands', lang)} />
                        <BrandList brands={brand} itemQuery={itemQuery} setBrand={(i) => setItemQuery({
                          ...itemQuery,
                          brand: i
                        })} />
                      </div>
                    }
                    {
                      step.length > 0 &&
                      <div className="step-wrap">
                        <SecTitle text="Steps" />
                        <Step step={step} setStep={(i) => setItemQuery({
                          ...itemQuery,
                          step: i
                        })} />
                      </div>
                    }
                  </>
                }
              </>
            }
            <SecTitle text={brandName === 'false' ? '' : special !== 'brands' ? decodeURIComponent(brandName) : decodeURIComponent(id)} />
            <div className='step-wrap step-result'>
              {
                products !== null &&
                <div className='row card-row'>
                  {
                    products.length === 0 ?
                      <div className='col-lg-12'>
                        <div className='noresult-wrap'>
                          <h4 className='no-result text-center'>No Product Found</h4>
                        </div>
                      </div>
                      :

                      products.slice((postSize * currentPage) - postSize, postSize * currentPage).map((info, key) => {
                        return (
                          <Card info={info} key={key} />
                        )
                      })
                  }
                </div>

              }
              {
                products !== null && products.length > 9 &&
                <div className='pagi-wrap'>
                  <Pagination
                    onChange={(current) => { setCurrentPage(current) }}
                    current={currentPage}
                    total={post}
                    defaultPageSize={postSize}
                  />
                </div>
              }
            </div>


          </div>
          <div className="col-lg-3 brand-sidebar">
            <Sidebar />
          </div>
        </div>
      </div>
    </article>
  )
}
