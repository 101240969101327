import React, { useState, useEffect} from 'react'
import Tab from '../../components/tab'
import BreadCrumb from '../../components/breadcrumb'
import ProfileSection from './_profile'
import OrderHistory from './_orderhistory'
import { useSelector } from 'react-redux'
import { get_order, getui } from '../../controller'
import { Link } from 'react-router-dom'
import './style.scss'
export default function Profile() {
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const profile = useSelector(state => state.login)
  profile === null && (window.location.href = '/')
  const [order, setorder] = useState(null)
  let uid = profile ? profile['userid'] : (window.location.href = '/login')
  let access_tokken = profile['access_token']
  useEffect(() => {
    get_order(uid, access_tokken).then(res => {
      res.length > 0 ? setorder(res) : setorder(res)
    })
    window.scrollTo(0, 0)
  }, [setorder])
  
  return (
    <article id="profile">
      <BreadCrumb pagename={getui(uitext, 'profile', lang)} />
      <div className="container">
        <Tab tabs={
          [
            {
              title: getui(uitext, 'profile', lang),
              content: <ProfileSection profile={profile}/>
            },
            {
              title: getui(uitext, 'order_history', lang),
              content: <OrderHistory order={order}/>
            },
            {
              title: <Link to='/wishlist'>{getui(uitext, 'wishlist', lang)}</Link>
            }
          ]
        }/>
      </div>
    </article>
  )
}
