const getotp = (state = JSON.parse(localStorage.getItem('getotp')), action) => {
  switch (action.type) {
    case 'GETOTP' :
      localStorage.setItem('getotp', JSON.stringify(action.data))
      return state
    default :
      return state
  }
}

export default getotp