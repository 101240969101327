import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { getui } from '../../controller'
// utality
import _ from 'underscore'
// components
import data from '../../data'
import { useSelector, useDispatch } from 'react-redux'
import NavCart from './_cartdrop'
import { delete_logout } from '../../controller'

const { general, navlink } = data
export default function PriNav() {
  const profile = useSelector(state => state.login)
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)

  const removeLogin = () => {
    localStorage.removeItem('login')
    window.location.href= window.location.origin+ '/'
  }
  
  const Logout = () => {
    let token = profile.access_token
    window.localStorage.removeItem('login')
    window.location.href = '/'
  }
  return (
    <nav className="pc-prinav">
      <div className="container">
        <figure className="logo">
          <Link to='/'>
            <img src={general.logo} alt={general.appname} />
          </Link>
        </figure>
        <ul className="nav-link">
          <li className="active">
            <Link to='/gallery/latest_items'>{getui(uitext, 'latest_products', lang)}</Link>
          </li>
          <li>
            <Link to='/promodiscount'>{getui(uitext, 'promo_discount', lang)}</Link>
          </li>
          <li>
            <Link to='/gallery/hottest_items'>{getui(uitext, 'hottest_items', lang)}</Link>
          </li>
          <li>
            <Link to='/contactus'>{getui(uitext, 'contact_us', lang)}</Link>
          </li>
          {
            profile === null &&
              <>
                <li className="lg-menu">
                  <Link to='/login'>{getui(uitext, 'sign_in', lang)}</Link>
                </li>
                <li className="lg-menu">
                  <Link to='/signup'>{getui(uitext, 'sign_up', lang)}</Link>
                </li>
              </>
          }
        </ul>
        <ul className="nav-link log-menu">
        {
            profile === null &&
              <>
                <li>
                  <Link to='/login'>{getui(uitext, 'sign_in', lang)}</Link>
                </li>
                <li>
                  <Link to='/signup'>{getui(uitext, 'sign_up', lang)}</Link>
                </li>
              </>
          }
        </ul>
        {
          profile !== null &&
          (
            <>
              {/* <div className='profile' onClick={() => set_profilevisible(!profilevisible)}> */}
              <div className='profile'>
                <p>{getui(uitext, 'welcome', lang)}&nbsp;<span className="text-primary">{profile[0].name.toUpperCase()}</span></p>
                {/* <div className={"profile-popup" + (profilevisible === true ? " active" : "")}> */}
                <div className="profile-popup">
                  <ul className='profile-item'>
                    <li><Link to='/profile'><img src={require('../../assets/img/user.svg')} alt="sb baby profile" />{getui(uitext, 'profile', lang)}</Link></li>
                    <li><Link to='/wishlist'><img src={require('../../assets/img/wishlist.svg')} alt="sb baby wishlist" />{getui(uitext, 'wishlist', lang)}</Link></li>
                    <li><Link to='/' onClick={() => Logout()}><img src={require('../../assets/img/logout.svg')} alt="sb baby logout" />{getui(uitext, 'logout', lang)}</Link></li>
                  </ul>
                </div>
              </div>
            </>
          )
        }
        <NavCart />
        <figure className="menu-bugger">
          <img src={require('../../assets/img/menu.png')} alt="sb baby open menu"/>
        </figure>

      </div>
    </nav>
  )
}
