const lang = (state = localStorage.getItem('lang') === null ? 'en' : localStorage.getItem('lang'), action) => {
  switch (action.type) {
    case 'EMIT':
      localStorage.setItem('lang', action.data)
      return action.data
    default :
      return state
  }
}

export default lang