const axios = require('axios')
const config = require('./_config')
export const get_prodlist = (param) => {
return axios.get(`${config.default.url}/list/product`, {
  params: {
    ...param
  }
})
.then(({data}) => {
  return data.data
})
.catch(() => "server error")
}
