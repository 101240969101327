const axios = require('axios')
const config = require('./_config')
export const get_prodtype = (param) => {
return axios.get(`${config.default.url}/product/type`, {
    params: {
        ...param
    }
})
.then((res) => {
    return res.data.data
})
.catch(() => "server error")
}