import React, {useState, useEffect} from 'react'
import currency from 'currency.js'
import _ from 'underscore'
import Table from '../../components/table'
import Button from '../../components/button'
import moment from 'moment'
import config from '../../config'
import { Link } from 'react-router-dom'
import Pagination from 'rc-pagination';
// import data from '../../data'
// const { orderhistory } = data
export default function OrderHistory({order}) {
  
  const [postSize, setpostSize] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)

  let post = order.quotation

  
  // console.log(order, '...')
  // let total_page = Math.ceil(post/post_per_page)
  // let rows = [];
  // let cols = [];
  // for(let i=0; i< order.length; i++){
  //   cols.push(<td>test</td>)
  //   if(i!== 0 && i % 5 ==0){
  //     rows.push(<tr>{cols}</tr>);
  //     cols = [];
  //   }
  // }
  // console.log(rows, '...')
  console.log(order, 'order')


  return (
    <section>
      <h3>My Order</h3>
      <Table 
      heads={['Index', 'Order No.', 'Order Date', 'Total Amount', '']}
      body={
        <React.Fragment>
          {
            order.quotation && 
            order.quotation.slice((postSize * currentPage) - postSize, postSize * currentPage).map((history, key) => (
              <tr key={key}>
                <td>
                  <strong>Index</strong>
                  {((postSize * currentPage) + key + 1) - postSize}
                </td>
                <td>
                  <strong>Order No.</strong>
                  {history.name}
                </td>
                <td>
                  <strong>Order Date</strong>
                  {moment(history.date_order).format('MMMM Do YYYY, h:mm:ss A')}
                </td>
                <td>
                  <strong>Total Amount</strong>
                  {currency(history.amount_total, config.currency).format()}
                </td>
                <td>
                  <Link to={'orderdetail/'+history.id+''} className='cusbtn btn-detail'>Detail</Link>
                </td>
              </tr>
              
            ))
            
          }
        </React.Fragment>
        
      }
      />
      <div className='pagi-wrap'>
        {
          post > postSize &&
          <Pagination
            onChange={(current) => {
              setCurrentPage(current)
              window.scrollTo(0, 0)
            } }
            current={currentPage}
            total={post}
            defaultPageSize={postSize}
          />
        }
      </div>
      {/* <h2>test</h2>
      <div>
        {
           _.chunk(order, 5).map((item, key) => <tr key={key}> {item.map(td => <td>hi</td>)}</tr>) 
        }
      </div> */}

    </section>
  )
}
