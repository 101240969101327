import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get_footer } from '../../controller'
import parse from 'html-react-parser'
import { getui } from '../../controller'
export default function Footer() {

  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [footer, setfooter] = useState(null)
  const [phone, setphone] = useState(null)
  const [email, setemail] = useState(null)

  useEffect(() => {
    get_footer({
      lang: lang
    })
      .then((res) => {
        setfooter(res)
        const phone = res[0].phone
        const email = res[0].email
        const phArray = phone.split(',')
        const mailArray = email.split(',')
        setphone(phArray)
        setemail(mailArray)
      })

  }, [setfooter, setphone, setemail])

  return (

    <footer className="footer">
      <section className="sec-footer">
        <article className="container">
          <section className="row">
            <div className='col-lg-4 info-content'>
              {
                footer !== null &&
                <>
                  <img src={footer[0].delivery_icon} alt={footer[0].delivery_name} />
                  {/* <img src={require("../assets/img/cart.png")}/> */}
                  <div className="content">
                    <h5 className="title">{footer[0].delivery_name}</h5>
                    <p>{parse(footer[0].delivery_description)}</p>
                  </div>
                </>
              }
            </div>
            <div className='col-lg-4 info-content'>
              {
                footer !== null &&
                <>
                  <img src={footer[0].payment_icon} alt={footer[0].payment_name} />
                  <div className="content">
                    <h5 className="title">{footer[0].payment_name}</h5>
                    <p>{parse(footer[0].payment_description)}</p>
                  </div>
                </>
              }
            </div>
            <div className='col-lg-4 info-content'>
              {
                footer !== null &&
                <>
                  <img src={footer[0].customer_service_icon} alt={footer[0].customer_service_name} />
                  <div className="content">
                    <h5 className="title">{footer[0].customer_service_name}</h5>
                    <p>{parse(footer[0].cu_service_description)}</p>
                  </div>
                </>
              }
            </div>
          </section>
        </article>
        <hr />
      </section>

      <article className="container section-contact">
        <section className="row">
          <div className="col-12">
            <h3>{getui(uitext, 'contact_info', lang)}</h3>
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">{getui(uitext, 'address', lang)}</span>
                <span>{footer[0].address}</span>
              </p>
            }
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">{getui(uitext, 'phone', lang)}</span>
                <span>
                  {
                    phone !== null &&
                    phone.map((info, key) =>
                      <React.Fragment key={key}>
                        <a href={"tel:" + info + ""}>{info}</a>
                      </React.Fragment>
                    )
                  }
                </span>
              </p>
            }
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">{getui(uitext, 'email', lang)}</span>
                <span>
                  {
                    email !== null &&
                    email.map((info, key) =>
                      <React.Fragment key={key}>
                        <a href={"mailto:" + info + ""}>{info}</a>
                      </React.Fragment>
                    )
                  }
                </span>
              </p>
            }
          </div>
        </section>
      </article>
      <hr />
      <article className="container">
        <section className="copyright">
          <div className='row'>
            <div className='col-lg-5 order-md-2 order-lg-1'>
              <p>Copyright &copy; {new Date().getFullYear()}.Innovix Solution Co., Ltd. All Rights Reserved. <br /> Powered by <a href='https://innovixdigital.com/' target='_blank' className='powerby'>Innovix Digital</a></p>
            </div>
            <div className='col-lg-7 order-md-1 order-lg-2'>
              <ul className='general-info'>
                <li>
                  <Link to={'/about'}>{getui(uitext, 'about_us', lang)}</Link>
                </li>
                <li>
                  <Link to={'/termandcondition'}>{getui(uitext, 'term_condition', lang)}</Link>
                </li>
                <li className="last">
                  <Link to={'/policy'}>{getui(uitext, 'privacy', lang)}</Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </footer>
  )
}
