import config from './_config'
const axios = require('axios')
export const post_payment = (order_id, acquirer_id, token) => {
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .post(`${config.url}/paynow?order_id=${order_id}&acquirer_id=${acquirer_id}`)
  .then(res => res.data)
  .catch(err => err)
}