import config from './_config'
const axios = require('axios')
// const data =  {    
//   "email":"trio@gmail.com",
//   "name":"trio",
//   "mobile":"09978954222",
//   "password":"123",
//   "street":"address1",
//   "street2":"address2",
//   "city":"Yangon",
//   "zip":"ZIPCODE",
//   "state_id":1385
// }
export const post_signup = (data) => {
  return axios.create()
  .post(`${config.url}/user/signup`, data)
  .then(res => res.data.result)
  .catch(err => err)
}