import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { open_popup } from '../../redux-actions/action'
export default function Popup({content}) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.popup)
  return (
    <div 
    className={"sb-popup " + (state === false && " d-none")}
    >
      <div 
      onClick={() => dispatch(open_popup(false))}
      className="sb-popup-close"></div>
      {state !== false && 
      <div className="popup-content">
        <img 
        onClick={() => dispatch(open_popup(false))}
        src={require('../../assets/img/close.svg')} 
        className="sb-popup-close-btn" alt=""/>
        {state.content}
      </div>}
    </div>
  )
}
