import config from './_config'
const axios = require('axios')
export const post_cart = (data, token) => {
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .post(`${config.url}/save/cart`, data)
  .then(res => {
    return res.data.result[0]})
  .catch(err => err)
}