import React, {useState} from 'react'

export default function BrandList({brands, setBrand}) {
  const [active, setactive] = useState(null)
  return (
    <div className='brandlist'>
        {
          brands.map((info,key) => 
            <div className={'brand-col' + (active === key ? ' active' : '')} key={key} onClick={() => {
                setBrand(key === active ? null : info.brand_id)
                key === active ? setactive(null) : setactive(key)
                window.scrollTo(0, document.querySelector('.product-step') ? document.querySelector('.product-step').offsetTop : document.querySelector('.step-result')?.offsetTop)
              }}>
              <div className="branditem">
                <img src={info.brand_image} alt={info.patch} />
              </div>
            </div>
          )
        }
    </div>
  )
}
