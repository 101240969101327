import React, { useState } from "react";
import PropTypes from "prop-types";
import { get_productqty } from "../../controller";

export default function Spanner({ value = 1, change }) {
  return (
    <div className="qty-spanner">
      <button
        onClick={() => {
          if (value > 1) {
            let qty = value - 1;
            change !== undefined && change(qty);
          }
        }}
      >
        <span>-</span>
      </button>
      <p className="mr-0">{value}</p>
      <button
        onClick={() => {
          let qty = value + 1;
          change !== undefined && change(qty);
        }}
      >
        <span>+</span>
      </button>
    </div>
  );
}
Spanner.propTypes = {
  value: PropTypes.number,
};
