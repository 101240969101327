import React, { useState } from 'react'
import PropTypes from 'prop-types'
export default function Tab({tabs=
  [
    {
      title: "Demo 1",
      content: 'demo content 1 (html/react-component)'
    },
    {
      title: "Demo 2",
      content: 'demo content 2(html/react-component)'
    }
  ], active}) {
  const [state, setstate] = useState(0)
  return (
    <section className="component-tab">
      <ul className="tab-listing">
        {
          tabs.map((tab, key) => (
            <li
            
            className={(key === state || active === null ? "active" : "")}
            onClick={() => setstate(key)}
            key={key}
            >{tab.title}</li>
          ))
        }
      </ul>
      <div className="content">
        {
          tabs[state].content
        }
      </div>
    </section>
  )
}
Tab.propTypes = {
  tabs: PropTypes.array.isRequired,
  active: PropTypes.number
}