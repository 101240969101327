import React from 'react'
import Button from '../components/button'
export default function NotFound() {
    return (
        <div className="notfound">
            <div className="container text-center">
                <div>
                    <img src={require('../assets/img/404.png')} alt="not found" style={styles.img} className="mb-5 mt-5"/>
                </div>
                <Button
                    text='GO BACK HOME'
                    play={() => window.location.href= `/`}
                />
            </div>
        </div>
    )
}

const styles = {
    img: {
        marginTop: '20px'
    }
}