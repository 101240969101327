import React, { useState, useEffect } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { useDispatch, useSelector } from 'react-redux'
import { mobile } from '../../redux-actions/action'
import { post_usercheck, getui } from '../../controller'
import './style.scss'

export default function Otp() {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const [error, setError] = useState(null)
  const [phone, setphone] = useState(null)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleChange = (e) => {
    setphone({
        ...phone,
        [e.target.id]: e.target.value
    })
  }
  let dispatch = useDispatch()
  const getphoneSuccess = () => {
    dispatch(mobile(phone.mobile))
    window.location.href= `/otp/usercheck`
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(phone)
    post_usercheck(phone)
    .then((res) => {
        // console.log(res.message)
        res.status === 'success' ?
        getphoneSuccess() :
        setError(res.message)
    })
  }
  return (
    <article id="otp">
      <section className="bg-form">
        <div className="container form-container">   
          <div className="row">
            <div className='col-12'>
              <h3>{getui(uitext, 'enter_phone', lang)}</h3>
              <div className="formsection">
                <form onSubmit={handleSubmit}>
                  <div className="form-name">
                      <p>Phone</p>     
                      <Input id="mobile" type="number" placeholder="phone" play={(e) => handleChange(e)} required />
                  </div>
                  <p className='error'>{error}</p>
                  <Button
                    text='Submit'
                    addclass="btn-checkout btn-login"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}