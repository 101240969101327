import React, { useEffect } from 'react'
import Button from '../../components/button'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getui } from '../../controller'
import { Link } from 'react-router-dom'
import './style.scss'

export default function Success() {
  let urlName = useParams().name
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const orderno = useSelector(state => state.orderno)
  const ordername = useSelector(state => state.ordername)
  const login = useSelector(state => state.login)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // if((urlName !== 'contact' || urlName !== 'ordersuccess') && login === null ) window.location.href = '/login' 
  return (
    <article id="success">
      <section>
        <div className="container p-5 text-center ">   
          <div className="row">
            <div className='col-12'>
              <div className="sucess-message">
                  {
                    urlName === 'register' ?
                    login !== null ? window.location.href = '/' :
                    <>
                      <img src={require('../../assets/img/tick.png')}/>
                      <h2>{getui(uitext, 'register_success', lang)}</h2>
                      <Button
                        text='Login'
                        play={() => window.location.href= `/login`}
                      />
                    </>
                    :
                    urlName === 'resetpass' ?
                    login !== null ? window.location.href = '/' :
                    <>
                      <img src={require('../../assets/img/tick.png')}/>
                      <h2>{getui(uitext, 'reset_success', lang)}</h2>
                      <Button
                        text='Login'
                        play={() => window.location.href= `/login`}
                      />
                    </>
                    :
                    urlName === 'changepass' ?
                    login !== null ? window.location.href = '/' :
                    <>
                      <img src={require('../../assets/img/tick.png')}/>
                      <h2>{getui(uitext, 'passchange_success', lang)}</h2>
                      <Button
                        text='Login'
                        play={() => window.location.href= `/login`}
                      />
                    </>
                    :
                    urlName === 'contact' ?
                    login !== null ? window.location.href = '/' :
                    <>
                      <img src={require('../../assets/img/tick.png')}/>
                      <h2>{getui(uitext, 'contact_success', lang)}</h2>
                      <Button
                        text='Browse Item'
                        play={() => window.location.href= `/`}
                      />
                    </>
                    :
                    urlName === 'ordersuccess' ?
                    <div className="mes-shopping">
                      <img src={require('../../assets/img/tick.png')}/>
                      <h2 className="text-center">{getui(uitext, 'order_success', lang)}</h2>
                      <p className="text-center orderid">Order ID: 
                      {
                        login !== null ? 
                        <Link to={'../orderdetail/'+orderno+''}>{ordername}</Link>
                        :
                        <span>{ordername}</span>
                      }
                      </p>
                      <p className="text-center"><Link to='/'>Do you want to continue shopping?</Link></p>                      
                    </div>
                    :
                    login !== null ? window.location.href = '/' :
                    window.location.href='/404'
                  }
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}