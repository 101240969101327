import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import currency from 'currency.js'
import Table from '../../components/table'
import { get_orderdetail } from '../../controller'
import { Link } from 'react-router-dom'
import './style.scss'
import config from '../../config'

export default function Orderdetail() {
  const profile = useSelector(state => state.login)
  profile === null && (window.location.href='/login')
  let orderid = useParams().orderid
  let access_tokken = profile['access_token']
  const [orderdetail, setorderdetail] = useState(null)
  
  useEffect(() => {
    get_orderdetail(orderid, access_tokken).then(res => setorderdetail(res))
    window.scrollTo(0, 0)
  },[setorderdetail])
  
  return (
    <article id="orderdetail">
      {
        orderdetail !== null &&
      <div className="container detailsection">
        <div className="detailform">
          <h3 className="">Order No. {orderdetail[0].sale_order}</h3>
          <p className="add-description">Shipping address : {orderdetail[0].street && orderdetail[0].street + ','} {orderdetail[0].state && orderdetail[0].state.split('|')[1] + ','} {orderdetail[0].state && orderdetail[0].state.split('|')[0]}</p>
          <Table
            heads={["INDEX", "PRODUCTS", "QUANTITY", "PRICE", "TOTAL PRICE"]}
            body={
              orderdetail[0].products.map((info, key) => (
                (
                  <tr key={key} className="product-row">
                    <td>
                      {key + 1}
                    </td>
                    <td>
                      <div className="product">
                        <div className="desc">
                          <p>{info.product} <small>{info.sku}</small></p>
                        </div>
                      </div>
                    </td>
                    <td>
                      {info.quantity}
                    </td>
                    <td>{currency(info.price_unit, config.currency).format()}</td>
                    <td>{info.total_price}</td>
                  </tr>
                )
              ))
            }
          />  
          
            <Table                 
              heads={["", "", ""]}
              body={
                <React.Fragment>
                  <tr className="totalprice">
                    <td className="blank">&nbsp;</td>   
                                        
                    <td className="price1">
                      <div>Subtotal :</div>
                    </td>
                    <td className="price2">
                      <div>KS {orderdetail[0].subtotal}</div>
                    </td>
                  </tr>

                  <tr className="totalprice">
                    <td className="blank">&nbsp;</td>   
                                        
                    <td className="price1">
                      <div>Total :</div>
                    </td>
                    <td className="price2">
                      <div>{orderdetail[0].total}</div>
                    </td>
                  </tr>

                  
                </React.Fragment>
              }
            />
            

          <div className="btn-history">
            <Link to='/profile' className='cusbtn btn-goto'>Go to Order List</Link>
          </div>
          
        </div>

      </div>
      }
    </article>
  )
}
