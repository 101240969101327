const axios = require('axios')
const config = require('./_config')

export const get_webgallerydetail = (param) => {
return axios.get(`${config.default.url}/list/website/gallery/detail`, {
  params: {
    ...param
  }
})
.then((res) => {
  return res.data.data
})
.catch(() => "server error")
}