import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import SecTitle from '../../components/sectiontitle';
import { getui } from '../../controller';
const config = require('../../controller/_config');




const HottestBrand = () => {
  const lang = useSelector(state => state.lang)
  const uitext = useSelector(state => state.translate)
  const [brands, setBrands] = useState([])
  useEffect(() => {
    axios.get(`${config.default.url}/brand/group?code=HBG`)
    .then(({data}) => {
      console.log(data.data.brand_list)
      setBrands(data.data.brand_list)
      // const ids = data.data.brand_list.map(item => item.id).toString()

      // axios.get(`${config.default.url}/product/brand?code=${ids}`)
      // .then(res => setBrands(res.data.data))
    })
  }, [setBrands])
  return (
    <>
      <SecTitle text={getui(uitext, 'rec_brands', lang)} />
      <div className="hottestBrand row">
      {
        brands.map((item, key) => 
          <div className='col-4 col-md-3' key={key}>
            <div className="brand-item">
              <Link to={`/brandlisting/brands/${item.name}/${item.id}`}>
                <img src={item.images_link || require('../../assets/img/no-img.jpg')} alt={item.patch} />
              </Link>
            </div>
          </div>
        )
      }
      </div>
    </>
  );
}

export default HottestBrand;