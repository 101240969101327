// npm module
import 'bootstrap/scss/bootstrap.scss'
// react module
import React, { Component, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ReactGA from 'react-ga';
import { get_translation } from './controller'
// custom module
import AppRoute from './routes/routes'
import Popup from './components/popup'
// uitext
import './i18n'
// style
import './App.scss'
import { update_lang } from './redux-actions/action'




export default function App() {
    
    const dispatch = useDispatch()
    useEffect(() => {
      get_translation()
      .then(res => dispatch(update_lang(res)))
    }, [])

    return (
      <main>
        <div id='open-bugger-wrap'><button><img src={require('./assets/img/close.svg')} alt='close' /></button></div>
        <Popup />
        <AppRoute />
      </main>
    )
}
