// react module
import React from 'react';
import ReactDOM from 'react-dom';
// redux module
import { createStore } from 'redux'
import { Provider } from 'react-redux'

// custom module
import App from './App';
import * as serviceWorker from './serviceWorker';
import STORE from './redux-reducer'

// declare store with dev midware
const store = createStore(STORE, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
