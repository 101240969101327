import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';
import config from '../../controller/_config'
import './style.scss'

const axios = require('axios')

const PolicyMobile = () => {
  const { lang } = useParams()

  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")

  axios(`${config.url}/privacy/policy?lang=${lang}`)
    .then(res => {
      let { name, privacy_description } = res.data.data[0]
      console.log(name)
      setTitle(name)
      setBody(privacy_description)
    })

  // useEffect(() => {
  //   let header = document.querySelector('header')
  //   header.classList.add('d-none')
  //   let footer = document.querySelector('footer')
  //   footer.classList.add('d-none')
  //   setTimeout(function() {
  //     let messenger = document.querySelector('#fc_frame')
  //     messenger && messenger.classList.add('d-none')
  //   }, 1000)
  // })

  return (
    <div className="container">
      <br />
      <h5>{title}</h5>
      <br />
      {parse(body)}
    </div>
  )
}

export default PolicyMobile