import config from './_config'
const axios = require('axios')

export const delete_logout = (token) => {
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .delete(`${config.url}/api/auth/token`)
  .then(res => res.data.data)
  .catch(err => err)
}