import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/breadcrumb'
import parse from 'html-react-parser';
import { get_terms } from '../../controller'
import './style.scss'

export default function Terms() {
  const lang = useSelector(state => state.lang)
  const [ terms, setterms ] = useState(null)
  useEffect(() => {
    get_terms({
      lang: lang
    })
    .then(res => res.length > 0 && setterms(res[0]))
    window.scrollTo(0, 0)
  }, [setterms])
  return (
    terms !== null &&
    <article id='terms'>
      <Breadcrumb pagename={terms.name} links={[
        {
          name: terms.name
        }
      ]}/>
      <section className='terms-wrap'>
        <div className='container'>
          <div className='row'>
            {
              <div className='col-lg-12' >
                <div className='terms-item'>
                  
                  <>
                    {parse(terms.terms_description)}
                  </>
                </div>
              </div>
            }              
          </div>
        </div>
      </section>
    </article>
  )
}
