import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { update, del, order, open_popup } from '../../redux-actions/action'
import _ from 'underscore'
import currency from 'currency.js'
import { get_productqty, get_delivery_charges, getui } from '../../controller'
import Table from '../../components/table'
import Spanner from '../../components/spanner'
import Button from '../../components/button'
import config from '../../config'
import { Link } from 'react-router-dom'
export default function Review({ play, state }) {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const [delivery, setDelivery] = useState(0)
  const shopping_cart = useSelector(state => state.cart)
  const login = useSelector(state => state.login)
  const dispatch = useDispatch()
  // const checkout = () => login ? play(state += 1) : window.location.href = '/login'
  const checkout = () => {
    play(state += 1)
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    login && login[0].state_id && get_delivery_charges({
      state_id: login[0].state_id
    })
    .then(res => setDelivery(res))
  }, [])
  
  return (
      <React.Fragment>
      {
        shopping_cart.length !== 0 ?
        <div className="col-lg-12">
          <div className="side-widget review">
            <Table
              heads={["PRODUCTS", "QUANTITY", "UNIT PRICE", "TOTAL", ""]}
              body={
                shopping_cart.map((info, key) => (
                  (
                    <tr key={key}>
                      <td>
                        <div className="product">
                          <img src={info.img || require('../../assets/img/no-img.jpg')} alt={info.name} className="prduct-img" />
                          <div className="desc">
                            <p>{info.name}</p>
                            <small className="text-secondary">{_.map(info.varient_name, data => data.name).toString()}</small>
                          </div>
                        </div>
                      </td>
                      <td className="amt-qty">
                        <Spanner value={info.product_uom_qty}
                          change={(e) => {
                            get_productqty({
                              product_id: info.product_id,
                              variant_id: info.varient_id,
                              qty: e
                            }).then((res) => {
                              if (res.instock === true) {
                                dispatch(update({ ...info, product_uom_qty: e }))
                              } else { 
                                dispatch(open_popup(
                                  {content : 
                                    <div className="alert-out-of-stock">
                                      <h2>This item is out of stock.</h2>
                                    </div>
                                  }
                                ))
                              }
                            })
                          }}
                        />
                      </td>
                      <td className="price">
                        {
                          currency(info.discount_price, config.currency).format()
                        }
                        {
                          info.percentage > 0 &&
                          <>
                            <br/>
                            <del>{currency(info.price, config.currency).format()}</del> 
                          </>
                        }
                      </td>
                      <td className="price">{currency(info.discount_price * info.product_uom_qty, config.currency).format()}</td>
                      <td>
                        <img
                          src={require('../../assets/img/delete-button.png')}
                          alt="remove sbbaby product from cart"
                          className="remove-item"
                          onClick={() => {
                            dispatch(del(info))
                          }}
                        /></td>
                    </tr>
                  )
                ))
              }
            />

            <div className="text-right">
              <hr className="cart-hr" />
              <ul className="pricelist" style={{ marginTop: 0 }}>

                <li style={{ border: 'none', marginTop: 0 }}>
                  <span className="totalspan">Total : </span>
                  <span className="currency">{currency(_.reduce(
                    _.map(shopping_cart, data => data.discount_price * data.product_uom_qty),
                    (memo, current) => memo + current
                  ), config.currency).format()}</span>
                </li>
                {/* <li style={{ border: 'none', marginTop: 0 }}>
                  <span className="totalspan">Delivery Charges : </span>
                  <span className="currency">{currency(delivery, config.currency).format()}</span>
                </li> */}
                {/* <li style={{ border: 'none', marginTop: 0 }}>
                  <span className="totalspan">Total : </span>
                  <span className="currency">{currency((_.reduce(
                    _.map(shopping_cart, data => data.discount_price * data.product_uom_qty),
                    (memo, current) => memo + current
                  ) + delivery), config.currency).format()}</span>
                </li> */}

              </ul>

            </div>
            <div className="row">
              <div className="col-6">
                <Button
                  text={getui(uitext, 'continue_shopping', lang)}
                  lfticn={require('../../assets/img/leftarrow-white.png')}
                  play={() => window.location.href = '/'}
                  addclass="btn-checkout"
                />
              </div>
              <div className="col-6 text-right">
                <Button
                  text={getui(uitext, 'order_now', lang)}
                  rhticn={require('../../assets/img/rightarrow-white.png')}
                  play={checkout}
                  addclass="btn-checkout"
                />
              </div>
            </div>

          </div>

        </div>
        :
        <section className='no-item-msg'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h2>{getui(uitext, 'no_items', lang)}</h2> <Link to='/' className='cusbtn'>{getui(uitext, 'continue_shopping', lang)}</Link>
              </div>
            </div>
          </div>
        </section>
      }
      </React.Fragment>
  )
}
