import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import _ from 'underscore'
import './style.scss'
import Card from '../../components/card'
import Sidebar from '../../components/sidebar'
import SecTitle from '../../components/sectiontitle'
import PromoBanner from '../../components/promobanner'
import { get_webgallerydetail, get_prodpricelist, getui } from '../../controller'
import Banner from './_banner'
import HottestBrand from './_brand';

const requestGallery = async (data) => await get_webgallerydetail(data)
const requestPromo = async (data) => await get_prodpricelist(data)

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
        window.gtag('config', 'G-GTX02WJT84', { 'page_title': 'Home', page_path: window.location.pathname + window.location.search })
    }, [])
    
    const lang = useSelector(state => state.lang)
    const uitext = useSelector(state => state.translate)
    const {data: hotItems, status: hotItemStatus} = useQuery([{
        group_code: 'hottest_items',
        lang
    }], requestGallery)

    const {data: latestItems, status: latestItemStatus} = useQuery([{
        group_code: 'latest_items',
        lang
    }], requestGallery)

    const {data: promos, status: promoStatus} = useQuery([{
        lang
    }], requestPromo)
    
    // weekpromos
    return (
        <div id="home">
            <Banner />
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        {
                            promoStatus === 'success' && 
                            <PromoBanner title={getui(uitext, 'monthly_promotion', lang)} promotions={_.filter(promos, item => item.promo_code === 'WP')}/>
                        }
                        {
                            promoStatus === 'success' && 
                            <PromoBanner title={getui(uitext, 'special_promotion', lang)} promotions={_.filter(promos, item => item.promo_code === 'SD')}/>
                        }
                        <br/>
                        
                        <HottestBrand />
                        <br/>
                        <SecTitle text={getui(uitext, 'hottest_items', lang)}/>
                        <div className='row card-row'>
                            {
                                hotItemStatus === 'success' && hotItems.slice(0, 6).map((info,key) => 
                                        <Card info={info} key={key} />
                                    )
                                }
                        </div>
                        <br/>
                        <br/>
                        <SecTitle text={getui(uitext, 'latest_products', lang)}/>
                        <div className='row card-row '>
                            {
                                latestItemStatus === 'success' && latestItems.slice(0, 6).map((info,key) =>
                                <Card info={info} key={key} />)
                            }
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <Sidebar />
                    </div>
                </div>
            </div>
        </div>
    )
}
