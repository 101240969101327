const demo = (state = 0, action) => {
    switch (action.type) {
        case 'INS': 
            return state += action.data
        case 'DEC':
            return state -= action.data
        default :
            return state
    }
}

export default demo