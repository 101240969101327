import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import Breadcrumb from '../../components/breadcrumb'
import { get_footer, post_contact, getui } from '../../controller'
import './style.scss'

export default function Contact() {
    const lang = useSelector(state => state.lang)
    const uitext = useSelector(state => state.translate)
    const [ footer, setfooter ] = useState(null)
    const [ phone, setphone ] = useState(null)
    const [ email, setemail ] = useState(null)
    const [ contact, setcontact ] = useState({})
    const [ error, seterror] = useState(false)
    useEffect(() => {
        window.gtag('config', 'G-GTX02WJT84', { 'page_title': 'Contact Us', page_path: window.location.pathname + window.location.search })
        get_footer({
            lang: lang
        })
        .then((res) => {
            setfooter(res)
            const phone = res[0].phone
            const email = res[0].email
            const phArray = phone.split(',')
            const mailArray = email.split(',')
            setphone(phArray)
            setemail(mailArray)
        })
        window.scrollTo(0, 0)
    }, [setfooter])

    const handleChange = (e) => {
        setcontact({
            ...contact,
            [e.target.id] : e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(contact)
        post_contact(contact)
        .then((res) => {
            res.status === 'success' ?
            window.location.href= '/success/contact' :
            seterror(true)
        })
    }
    
    return (
        <article id="contactus">
            
            <Breadcrumb pagename={getui(uitext, 'contact_us', lang)}/>
            <section className="contactwrap">
                <div className="container contact-form">
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-style">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-name">
                                        <p className='required'>Name</p>                                
                                        <Input id="name" type="text" placeholder="Name" play={(e) => handleChange(e)} required />
                                    
                                    </div>
                                    <div className="form-name">
                                        <p className='required'>Email</p>                                
                                        <Input id="email" type="email" placeholder="Email" play={(e) => handleChange(e)} required />
                                    
                                    </div>
                                    <div className="form-name">
                                        <p className='required'>Phone</p>                                
                                        <Input id="phone" type="number" placeholder="Phone" play={(e) => handleChange(e)} required />
                                    
                                    </div>
                                    <div className="form-name">
                                        <p className='required'>Subject</p>                                
                                        <Input id="subject" type="text" placeholder="Subject" play={(e) => handleChange(e)} required />
                                    
                                    </div>
                                    <div className="form-name">
                                        <p className='required'>Message</p>                                
                                        <Input id="message" type="textarea" placeholder="Address" play={(e) => handleChange(e)} required />
                                    
                                    </div>
                                    <p className="error">{error && 'Your Message fail to send'}</p>
                                    <div className="btn-send">
                                        <Button  
                                            text="SEND MESSAGE"                                
                                            addclass="btn-checkout btn-login"
                                        /> 
                                    </div>
                                </form>
                            </div> 
                        </div>
                            
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-6">
                            <div className="info-desc">
                                <div className="pin-warp">
                                    <img
                                        src={require('../../assets/img/pin.svg')}
                                        
                                    />
                                    <div className="address-text">
                                        {
                                            footer !== null &&
                                            <span>{footer[0].address}</span>
                                        }
                                    </div>
                                </div>

                                <div className="pin-warp">
                                    <img
                                        src={require('../../assets/img/phone.svg')}
                                        
                                    />
                                    <div className="phone-text">
                                        {
                                            phone !== null &&
                                            phone.map((info,key) => 
                                                <React.Fragment key={key}>
                                                    <a href={"tel:"+info+""}>{info}</a>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="pin-warp">
                                    <img
                                        src={require('../../assets/img/mail.svg')}
                                        
                                    />
                                    <div className="phone-text">
                                        {
                                            email !== null &&
                                            email.map((info,key) => 
                                                <React.Fragment key={key}>
                                                    <a href={"mailto:"+info+""}>{info}</a>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    )
}
