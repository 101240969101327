import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/breadcrumb'
import Card from '../../components/card'
// import './style.scss'
import { get_promolist } from '../../controller'
import './style.scss'

export default function Promolist() {
    const lang = useSelector(state => state.lang)
    const [ products, setproducts ] = useState(null)
    const promoid = useParams().promoid
    useEffect(() => {
        get_promolist({
            pricelist_item_id: promoid,
            lang: lang
        })
        .then(res => {
            setproducts(res)
        })
        window.scrollTo(0, 0)
    }, [setproducts])
    // console.log(products, 'products')
    return (
        <article id="productsearchresult">
        
            {
                products !== null &&
                <>
                    <Breadcrumb pagename={products.product_list[0].promo_name} links={[
                        {
                            name: products.product_list[0].promo_name
                        }
                    ]}/>
                    <div className="container desc">
                        <p>{products.product_list[0].promo_desc}</p>
                    </div>
                </>
            }

            {
                products !== null &&
                <div className="container searchresult">
                
                  <div className='row card-row'>
                    {
                        products.product_list.length === 0 ?
                        <div className='col-lg-12'>
                            <div className='noresult-wrap'>
                                <h4 className='no-result'>No Result Found</h4>
                            </div>
                        </div>
                        :
                        products.product_list.map((info,key) => {
                        return <Card info={info} key={key} />
                        })
                    }
                  </div>
            
                </div>
        
            }
            </article>
    )
}
