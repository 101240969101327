import React, { useState, useEffect } from 'react'
import Button from '../../components/button'
import Input from '../../components/fromdata'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getotp } from '../../redux-actions/action'
import { post_otp, getui } from '../../controller'
import './style.scss'

export default function Otp() {
  const uitext = useSelector(state => state.translate)
  const lang = useSelector(state => state.lang)
  const login = useSelector(state => state.login)
  login !== null && (window.location.href = '/')
  const mobile = useSelector(state => state.mobile)
  const [otp, setotp] = useState(null)
  const [error, setError] = useState(null)
  let urlName = useParams().name

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const handleChange = (e) => {
    setotp({
        [e.target.id]: Number(e.target.value),
        mobile
    })
  }
  let dispatch = useDispatch()
  const otpSuccess = () => {
    dispatch(getotp(otp.otp))
    urlName === 'usercheck' ?
    window.location.href= window.location.origin + `/resetpassword` :
    window.location.href= window.location.origin + `/success/register`
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(otp)
    post_otp(otp)
    .then((res) => {
        // console.log(res)
        res.status === 'success' ?
        otpSuccess() :
        setError(res.message)
    })
  }
  return (
    <article id="otp">
      <section className="bg-form">
        <div className="container form-container">   
          <div className="row">
            <div className='col-12'>
              <h1>{getui(uitext, 'otp_verify', lang)}</h1>
              <div className="formsection">
                <form onSubmit={handleSubmit}>
                  <div className="form-name">
                      <p>OTP</p>     
                      <Input id="otp" type="number" placeholder="OTP" play={(e) => handleChange(e)} required />
                  </div>
                  <p className='error'>{error && 'Invalid OTP number'}</p>
                  <p>We will send OTP to this number {mobile}</p>
                  <Button
                    text='Verify'
                    addclass="btn-checkout btn-login"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  )
}