export const emit = data => ({ type: 'EMIT', data })

export const login_action = data => ({ type: 'LOGIN', data })

export const ins = data => ({ type: 'INS', data })

export const update = data => ({ type: 'UPDATE', data })

export const del = data => ({ type: 'DEL', data })

export const reset = data => ({ type: 'RESET', data })

export const order = data => ({ type: 'ORDER', data })

export const order_name = data => ({ type: 'ORDERNAME', data })

export const mobile = data => ({ type: 'MOBILE', data })

export const otp = data => ({ type: 'GETOTP', data })

export const update_lang = data => ({ type: 'update_lang', data })

export const getotp = data => ({ type: 'GETOTP', data })

export const open_popup = data => ({ type: 'OPEN_POPUP', data })

export const cate_key = data => ({ type: 'CATEKEY', data })