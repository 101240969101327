import _ from 'underscore'
import { act } from 'react-dom/test-utils'
const cart = (state = localStorage.getItem('cart') === null ? [] : JSON.parse(localStorage.getItem('cart')), action) => {
  let pusher
  switch (action.type) {
      case 'INS':
        let new_item = action.data
        let uniqueid = new_item.product_id + new_item.varient_id
        new_item.uniqueid = uniqueid
        let old_item = _.find(state, item => item.uniqueid === uniqueid)
        if(old_item) new_item.product_uom_qty += old_item.product_uom_qty
        pusher = _.sortBy([..._.filter(
          state, item => {
            return item.uniqueid !== new_item.uniqueid}
        ), new_item], 'uniqueid')
        localStorage.setItem('cart', JSON.stringify(pusher))
        window.location.href = '/cart'
        return pusher
      case 'UPDATE':
        pusher = _.sortBy([..._.filter(
          state, item => item.uniqueid !== action.data.uniqueid
        ), action.data], 'uniqueid')
        localStorage.setItem('cart', JSON.stringify(pusher))
        return pusher
      case 'DEL':
        let del = state.filter(i => i !== action.data)
        localStorage.setItem('cart', JSON.stringify(del))
        return del
      case 'RESET':
        localStorage.setItem('cart', JSON.stringify([]))
        return []
      default :
          return state
  }
}

export default cart